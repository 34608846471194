import Img1 from './img/img1.jpg'
import Img6 from './img/img6.jpeg'
import Img7 from './img/img7.png'
import Img8 from './img/img8.jpg'
import Img9 from './img/img9.png'
import Img10 from './img/img10.jpg'
import Img12 from './img/1 Contabilidad y Nominas.jpg'
import Img13 from './img/2 Contabilidad y Bancos.jpg'
import Img14 from './img/3 Contabilidad y Comercial Premium.jpg'
import Img15 from './img/4 Contabilidad y Comercial PRO.jpg'
import Img16 from './img/5 Bancos y Nóminas.jpg'
import Img17 from './img/6 Bancos y premium.jpg'
import Img18 from './img/7 Licencia anual.jpg'
import Img19 from './img/8 Preventa fiscal.jpg'
import Img20 from './img/9 Contabilidad.jpg'
import Img21 from './img/11 demostracion promo.jpg'


const data = {
    cardData:[
        {
            id: 1,
            imgSrc: Img1,
            title: 'Carta Porte',
            desc: 'Con nosotros puedes asesorarte e implementar la carta porte para tus traslados en territorio nacional.'
        },

        {
            id: 12,
            imgSrc: Img12,
            title: 'Contabilidad y Nominas',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 50% de descuento para los sistemas de contabilidad y nominas'
        },

        {
            id: 13,
            imgSrc: Img13,
            title: 'Contabilidad y Bancos',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 50% de descuento para los sistemas de contabilidad y bancos'
        },

        {
            id: 14,
            imgSrc: Img14,
            title: 'Contabilidad y Comercial Premium',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 50% de descuento para los sistemas de contabilidad y comercial premium'
        },

        {
            id: 15,
            imgSrc: Img15,
            title: 'Contabilidad y Comercial PRO',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 50% de descuento para los sistemas de contabilidad y comercial PRO'
        },

        {
            id: 16,
            imgSrc: Img16,
            title: 'Bancos y Nóminas',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 50% de descuento para los sistemas de bancos y nominas'
        },

        {
            id: 17,
            imgSrc: Img17,
            title: 'Bancos y Comercial Premium',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 50% de descuento para los sistemas de bancos y comercial premium'
        },

        {
            id: 18,
            imgSrc: Img18,
            title: 'Licencia Anual',
            desc: 'Acude con nostros y pregunta por esta gran promoción del 30% de descuento para la licencia anual'
        },

        {
            id: 19,
            imgSrc: Img19,
            title: 'Preventas Fiscal',
            desc: 'Acude con nostros y pregunta por esta gran promoción para actulizar los sistemas CONTPAQ para el proximo año y tener en regla los nuevos requisitos fiscales'
        },

        {
            id: 20,
            imgSrc: Img20,
            title: 'Contabilidad y Bancos',
            desc: 'Acude con nostros y pregunta por esta gran promocion del 15% de descuento para la actulizacion de los sistemas de contabilidad y bancos'
        },

        {
            id: 21,
            imgSrc: Img21,
            title: 'Demostracion de Sistemas CONTPAQi',
            desc: '¿Quieres manejar los sietmas CONTPAQi de la mejor manera? Acude a estas denostraciones, solo escanea el codigo QR de la demostracion que deseas'
        },

        {
            id: 6,
            imgSrc: Img6,
            title: 'Escritorio Virtual',
            desc: 'Convierte cualquier espacio en tu oficina desde $1,690.00. Gracias a nuestra herramienta de Escritorio Virtual, en la cual podrás acceder a sistemas CONTPAQi y continuar con tus operaciones.'
        },

        {
            id: 7,
            imgSrc: Img7,
            title: 'Respaldos',
            desc: 'CONTPAQi® Respaldos es la aplicación que te permitirá tener toda tu información de los sistemas CONTPAQi® desde $1,490.00 en un solo lugar.'
        },

        {
            id: 8,
            imgSrc: Img8,
            title: 'CONTPAQi Contabilidad',
            desc: 'Olvídate de la captura manual y los procesos difíciles, con el sistema contable más completo y versátil para cualquier contador: CONTPAQi Contabilidad.'
        },

        {
            id: 9,
            imgSrc: Img9,
            title: 'CONTPAQi Decide',
            desc: 'CONTPAQi® Decide es la nueva herramienta que le permitirá a tu empresa consultar y monitorear su operación a través de indicadores de negocio.'
        },

        {
            id: 10,
            imgSrc: Img10,
            title: 'CONTPAQi XML en Linea',
            desc: 'Con nuestro software especializado en la descarga de XML desde el SAT y de forma masiva, podrás mejorar tu control administrativo y agilizar el registro contable de tus operaciones.'
        }

    ],
};

export default data;