import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import Logo from './Logo';


function App() {
  return (
    <>
    <div class="alert alert-primary text-center" role="alert">
      <Logo/>    
        {/*<h3 class="alert-heading">SEISA Informática Empresarial</h3>*/}
        <p class="mb-0">Aquí podrá ver las diferentes promociones que estamos manejando el mes de diciembre.</p>
        <p class="mb-0">Comuníquese con nosotros al: (993) 3154948 ó (993) 3155384</p>
        <p class="mb-0">Email: seisacomercial@gmail.com - seisacomercial2@gmail.com - seisacomercial3@gmail.com</p>
    </div> 
      <Home/>
    </>
  );
}

export default App;
