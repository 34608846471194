import React from 'react'
import logoSrc from './img/logo.png'

function Logo() {
    const divStyle = {display : 'block', margin : 'auto', marginRigth : 'auto', height: '125px', width: '220px'};
    return (
        <>
            <img src={logoSrc} style={divStyle} />
        </>
    )
}

export default Logo;
