import React, {useState} from 'react';
import data from './data';
import Model from './model';



const Home = () => {
    const [model, setModel] = useState(false);
    const [tempdata, setTempdata] = useState([]);

    const getData = (img, title, subtitle, desc) =>{
        let tempData = [img, title, subtitle, desc];
        setTempdata(item => [1, ...tempData]);

        return setModel(true);
    }
    return(
       <>
            <section className="py-4 py-lg-1 container">
                <div className="row justify-content-center aling-item-center">

                    {data.cardData.map((item, index)=>{
                        return(
                            <div className="col-11 col-md-6 col-lg-3 mx-0 mb-4" key={index}>
                                <div className="card p-0 overflow-hidden h-100 shadow">
                                    <img src={item.imgSrc} className="card-img-top" onClick ={() => getData(item.imgSrc, item.title, item.desc)}/>
                                        <div className="card-body">
                                            <h5 className="card-title text-center">{item.title}</h5>
                                            <h5 className="card-title text-danger text-center">{item.subtitle}</h5>
                                            <p className="card-text text-aling">{item.desc}</p> 
                                        </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </section>
            {
                model === true ? <Model img={tempdata[1]} title={tempdata[2]} subtitle={tempdata[3]} desc={tempdata[4]} hide={() =>setModel (false)}/>: ''
            }
            
       </>
    )
}

export default Home